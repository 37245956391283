<template>
    <div class="home">
        <div class="container">
            <div class="good-item">
                <img :src="product.thumb" class="goods-thumb" alt="">
                <div class="goods-info">
                    <h2 class="van-multi-ellipsis--l2">{{ product.name }}</h2>
                    <p class="goods-desc van-multi-ellipsis--l3">{{ product.description }}</p>
                    <div class="goods-price">¥ {{ product.price }}</div>
                </div>
            </div>
            <div v-if="formMakesure">
                <div class="order-confirm">
                    <h2 class="order-confirm-title">请确认以下信息是否填写正确</h2>
                    <van-cell-group>
                        <van-cell title="学校班级" :value="classValue"/>
                        <van-cell title="学生姓名" :value="orderForm.name"/>
                        <van-cell title="家长手机" :value="orderForm.mobile"/>
                        <van-cell title="备注信息" :value="orderForm.remark"/>
                    </van-cell-group>
                </div>
                <div class="confirm-btns">
                    <van-button icon="passed" round type="primary" block :loading="formLoading" @click="createOrder()"
                                style="margin-bottom: 10px;">确认无误 提交订单
                    </van-button>
                    <van-button icon="question-o" round type="default" block @click="formMakesure = false">信息有误
                        返回修改
                    </van-button>
                </div>
            </div>
            <van-form @submit="onSubmit" v-else>
                <van-field
                    v-model="classValue"
                    readonly
                    label="学校班级"
                    placeholder="点击选择所在学校及班级信息"
                    :rules="[{ required: true, message: '请选择学校及班级信息' }]"
                    @click="showSchoolClass = true"
                />
                <van-field
                    v-model="orderForm.name"
                    name="学生姓名"
                    label="学生姓名"
                    placeholder="学生姓名"
                    :rules="[{ required: true, message: '请填写学生姓名' }]"
                />
                <van-field
                    v-model="orderForm.mobile"
                    type="number"
                    name="家长手机"
                    label="家长手机"
                    placeholder="家长手机"
                    :rules="[{ required: true, message: '请输入家长手机号码' },{validator: mobileValidator, message: '请输入正确的手机号码'}]"
                />
                <van-field
                    v-model="orderForm.remark"
                    name="备注信息"
                    label="备注信息"
                    placeholder="备注信息"
                />
                <div style="margin: 16px;">
                    <van-button round block type="primary" native-type="submit">提交</van-button>
                </div>
            </van-form>
        </div>
        <van-popup v-model="showSchoolClass" round position="bottom">
            <van-cascader
                v-model="orderForm.classid"
                title="请选择学校班级信息"
                :options="schoolList"
                :field-names="schoolFields"
                @close="showSchoolClass = false"
                @finish="classFinish"
            />
        </van-popup>
    </div>
</template>

<script>
import {GET_PRODUCT_INFO} from "@/api/product"
import {GET_SCENE_SCHOOL_LIST} from "@/api/school"
import {CREATE_ORDER} from "@/api/order"
import {NavBar, Form, Field, Button, Cascader, Popup, Cell, CellGroup} from "vant";

export default {
    name: 'Home',
    components: {
        [NavBar.name]: NavBar,
        [Form.name]: Form,
        [Field.name]: Field,
        [Button.name]: Button,
        [Cascader.name]: Cascader,
        [Popup.name]: Popup,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
    },
    data() {
        return {
            formLoading: false,
            formMakesure: false,
            pid: 0,
            scene_id: 0,
            product: {},
            showSchoolClass: false,
            cascaderValue: '',
            classValue: '',
            schoolFields: {
                text: 'name',
                value: 'id'
            },
            schoolList: [],
            orderForm: {
                name: '',
                mobile: '',
                remark: '',
                classid: ''
            }
        }
    },
    created() {
        const pid = this.$route.query && this.$route.query.pid
        if (pid) {
            this.pid = pid
        }
        if (this.pid === 0) {
            this.$router.push({path: '/'})
        }

        this.scene_id = localStorage.getItem("sceneId");

        if (this.scene_id === "0") {
            this.$router.push({ path: '/' })
        }

        this.getProductInfo(pid)
        this.getSchoolList(this.scene_id)
    },
    methods: {
        getProductInfo(pid) {
            GET_PRODUCT_INFO(pid)
                .then(res => {
                    this.product = res.data
                })
        },
        getSchoolList(scene_id) {
            GET_SCENE_SCHOOL_LIST(scene_id)
                .then(res => {
                    this.schoolList = res.data
                })
        },
        classFinish({selectedOptions}) {
            this.showSchoolClass = false;
            this.orderForm.classid = selectedOptions.map((option) => option.id).join('|');
            this.classValue = selectedOptions.map((option) => option.name).join('/');
        },
        mobileValidator(val) {
            return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(val);
        },
        onSubmit() {
            this.formMakesure = true
        },
        createOrder() {
            this.formLoading = true
            CREATE_ORDER({
                ...this.orderForm,
                classname: this.classValue,
                product_id: this.pid,
                scene_id: this.scene_id
            })
                .then(res => {
                    this.formLoading = false
                    if (res.code === 0) {
                        this.$router.push({
                            path: '/order',
                            query: {
                                order_sn: res.data.order_sn
                            }
                        })
                    }
                })
                .catch(() => {
                    this.formLoading = false
                })
        }
    }
}
</script>

<style scoped lang="scss">
.good-item {
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.goods-thumb {
    width: 150px;
    height: 150px;
    margin-right: 20px;
    flex-shrink: 0;
    flex-grow: 0;
}

.goods-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}

.goods-info h2 {
    font-size: 28px;
}

.goods-desc {
    font-size: 24px;
    color: #999;
}

.goods-price {
    margin: 20px 0;
    font-size: 36px;
    color: #ff0000;
}

.order-confirm {
    background: #fff;
    padding: 30px;
}

.order-confirm .van-cell__title {
    flex: auto;
    width: 160px;
    flex-shrink: 0;
}

.order-confirm .van-cell__value {
    flex: auto;
    flex-grow: 1;
}

.order-confirm-title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 30px;
    color: red;
}

.confirm-btns {
    padding: 30px;
}
</style>
