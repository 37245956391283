<template>
  <div class="home">
    <div class="container">
      <div class="paytips">
        <img class="paytips-img" src="@/assets/paysuccess.png" alt="">
        <div class="orderinfo">
          <van-cell-group>
            <van-cell title="订单编号" :value="order_sn" />
            <van-cell title="产品名称" :value="orderinfo.product_name" />
            <van-cell title="学校班级" :value="orderinfo.classname" />
            <van-cell title="学生姓名" :value="orderinfo.name" />
            <van-cell title="家长手机" :value="orderinfo.mobile" />
            <van-cell title="备注信息" :value="orderinfo.remark" />
            <van-cell title="订单金额" value-class="priceStyle" :value="'¥ ' + orderinfo.amount" />
            <van-cell title="支付状态" value-class="paySuccess" value="已支付" />
            <van-cell title="支付时间" :value="orderinfo.pay_time" />
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button,Cell,CellGroup } from "vant";
import { GET_ORDER_INFO } from "@/api/order"
export default {
  name: 'Paysuccess',
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data () {
    return {
      order_sn: 0,
      orderinfo: {}
    }
  },
  created() {
    const order_sn = this.$route.query && this.$route.query.order_sn
    if (order_sn) {
      this.order_sn = order_sn
    }
    if (this.order_sn == undefined || this.order_sn === 0) {
      this.$router.push({ path: '/' })
    }
    this.getOrderInfo(order_sn)
  },
  methods: {
    getOrderInfo(order_sn) {
      GET_ORDER_INFO(order_sn)
          .then(res => {
            this.orderinfo = res.data
            if(this.orderinfo.status == 0){
              this.$router.push({
                path: '/order',
                query: {
                  order_sn:this.order_sn
                }
              })
            }
          })
    }
  }
}
</script>

<style scoped>
.paytips{
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paytips-img{
  width: 480px;
}
.orderinfo{
  width: 100%;
}
.orderinfo .van-cell__title{
  flex: auto;
  width: 160px;
  flex-shrink: 0;
}
.orderinfo .van-cell__value{
  flex: auto;
  flex-grow: 1;
}
.priceStyle{
  color: red;
  font-size: 36px;
}
.paySuccess{
  color: #07c160;
}
</style>
