import Vue from 'vue'
import VueRouter from 'vue-router'

import util from '@/libs/util.js'

import Home from '../views/Home.vue'
import My from '../views/My.vue'
import Product from '../views/Product.vue'
import Scene from '../views/Scene.vue'
import Cart from '../views/Cart.vue'
import Order from '../views/Order.vue'
import Paysuccess from '../views/Paysuccess.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta : {
      auth:true
    }
  },
  {
    path: '/my',
    name: 'My',
    component: My,
    meta : {
      title: '个人中心',
      auth:true
    }
  },
  {
    path: '/scene',
    name: 'Scene',
    component: Scene,
    meta : {
      title: '场景详情',
      // auth:true
    }
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    meta : {
      title: '产品详情',
      auth:true
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta : {
      title: '在线下单',
      auth:true
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
    meta : {
      title: '订单详情',
      auth:true
    }
  },
  {
    path: '/paysuccess',
    name: 'Paysuccess',
    component: Paysuccess,
    meta : {
      title: '订单支付成功',
      auth:true
    }
  },
  {
    path: '/404',
    name: 'error',
    component: () => import('@/views/error')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.afterEach(to => {
  // 更改标题
  util.title(to.meta.title)
})

export default router
